@import 'variables';

.divingspot {
    border-top: 1px solid black;
}

.divingheadline {
    // color: $linkcolor;
    font-family: $font-h;
    // font-weight: bolder;
    font-size: 50px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.divingstats {
    font-size: larger;
}

.ds {
    color: $linkcolor;
    font-size: larger;
    font-weight: bold;
}

.img-thumbnail {
    cursor: pointer;
    width: 200px;
    height: auto;
    margin-right: 20px;
}

.icon {
    max-width: 30px;
    height: auto;
    // vertical-align: text-bottom;
    margin-right: 20px;
}

a:hover {
    text-decoration: none;
}

@media screen and (max-width: 1296px) {
  
    .divingstats {
       font-size: medium;
    }

    .divingheadline {
        font-size: 50px;
    }

  
}


#spotMap {
  margin-top: 1%;
  border: solid 1px $linkcolor;
}

.popupImg {
  max-width: 134px;
  height: auto;
  margin-top: 7px;
  border-radius: 8%;
  overflow: hidden;
  border: rgb(70, 135, 255) solid 1px;
}

.popupTextHeadline {
  padding: 0 !important;
  margin: 0 !important;
  font-weight: bold;
  font-size: 20px;
  color: $linkcolor;
}

.popupText {
  margin: 0 !important;
  font-size: 12px;
}

.popupTextFirst {
  padding-top: 6px;
 }

.leaflet-popup-content {
  min-width: 240px;
}

.leaflet-popup-content-wrapper {
  background-color: rgba(255, 255, 255, 0.6);
}

.popupButton {
  // max-height: 20px;
  margin-top: 10px;
}


/////////////////////
////////////////////

.row > .column {
    padding: 0 8px;
  }
  
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Create four equal columns that floats next to eachother */
  .column {
    float: left;
    width: 25%;
  }
  
  /* The Modal (background) */
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
  }
  
  /* Modal Content */
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    width: 90%;
    max-width: 1200px;
  }
  
  /* The Close Button */
  .close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* Hide the slides by default */
  .mySlides {
    display: none;
  }
  
  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
  }
  
  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }
  
  /* Caption text */
  .caption-container {
    text-align: center;
    background-color: black;
    padding: 2px 16px;
    color: white;
  }
  
  img.demo {
    opacity: 0.6;
  }
  
  .active,
  .demo:hover {
    opacity: 1;
  }
  
  img.hover-shadow {
    transition: 0.3s;
  }
  
  .hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
