
  
  /* The Modal (background) */
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  /* Modal Content */
  .modal-content {
    position: relative;
    // top: 10%;
    background-color: #000000;
    margin: auto;
    padding-top: 10px;
  }
  
  /* The Close Button */
  .close {
    color: rgb(255, 255, 255) !important;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 55px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* Hide the slides by default */
  .mySlides {
    display: none;
  }
  
  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: rgb(255, 255, 255) !important; 
    font-weight: bold;
    font-size: 60px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
  }
  
  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: rgba(58, 58, 58, 0.8);
  }
  
  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    background-color: rgba(58, 58, 58, 0.8);
    font-size: 16px;
    margin-top: 10px;
    position: absolute;
    top: 0;
  }
  
  /* Caption text */
  .caption-container {
    text-align: center;
    background-color: black;
    padding: 2px 16px;
    color: white;
  }
  
  img.demo {
    opacity: 0.6;
  }
  
  .active,
  .demo:hover {
    opacity: 1;
  }
  
  img.hover-shadow {
    transition: 0.3s;
  }
  
  .hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .lightboxThumb {
      max-width: 150px;
      height: auto;
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 10px;
  }

  .mySlides {
    margin: auto;
    // width: 100%;
  }

  // .lightboxImg {
  //   margin: auto;
  //   width: 70%;
  // }

  .thumbnailcontrol {
      margin: auto;
      width: 70%;
  }